import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAlbumEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAlbumEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  sb_SelectFormat: any[];
  sb_SelectFormat_dummy: any[];
  SelectParcalar: any[];
  SelectParcalar_dummy: any[];
  SelectTur: any[];
  SelectTur_dummy: any[];
  SelectAlbumByID: any[];
  SelectAlbumByID_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  PhotoFromGallery: string;
  PhotoFromGallery_dummy: string;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DeleteParcalar2: number;
  DeleteParcalar2_dummy: number;
  DeleteParcalar: number;
  DeleteParcalar_dummy: number;
  SaveAlbum2: number;
  SaveAlbum2_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  isComp455678Visible: "visible" | "hidden";
  isComp50245Visible: "visible" | "hidden";
  isComp788722Visible: "visible" | "hidden";
  isCompalbumekle_47499AuthorizationVisible: "visible" | "hidden";
}

export class AlbumEkle_ScreenBase extends React.PureComponent<IAlbumEkle_ScreenProps, any> {
  albumekle_797852_value_kuikaSelectBoxRef: React.RefObject<any>;
  albumekle_561781_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "6edc1dca-6222-4d14-96bd-0c238369eee6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 962234, PropertyName: "value", Value: "Albüm Kartı" },
        { Id: 808843, PropertyName: "value", Value: "Ürün Adı" },
        { Id: 518120, PropertyName: "value", Value: "Türü" },
        { Id: 253938, PropertyName: "value", Value: "Format" },
        { Id: 697781, PropertyName: "value", Value: "Sanatçı" },
        { Id: 871414, PropertyName: "value", Value: "Dil" },
        { Id: 672978, PropertyName: "value", Value: "Yapım Yılı" },
        { Id: 633303, PropertyName: "value", Value: "Stok Durumu" },
        { Id: 760353, PropertyName: "value", Value: "Satışta mı?" },
        { Id: 429963, PropertyName: "value", Value: "Dijital Satışta mı?" },
        { Id: 79011, PropertyName: "value", Value: "Albüm İçeriği" },
        { Id: 50245, PropertyName: "label", Value: "Yeni Ekle" },
        { Id: 561781, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 548823, PropertyName: "title", Value: "Title" },
        { Id: 325379, PropertyName: "value", Value: "[datafield:trackno]" },
        { Id: 564903, PropertyName: "title", Value: "Title" },
        { Id: 989562, PropertyName: "value", Value: "[datafield:parcaadi]" },
        { Id: 823966, PropertyName: "title", Value: "Title" },
        { Id: 47499, PropertyName: "label", Value: "Sil" },
        { Id: 56852, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.albumekle_161175_value_kuikaTextInputRef = React.createRef();
    this.albumekle_574065_value_kuikaTextInputRef = React.createRef();
    this.albumekle_797852_value_kuikaSelectBoxRef = React.createRef();
    this.albumekle_682017_value_kuikaTextInputRef = React.createRef();
    this.albumekle_637281_value_kuikaTextInputRef = React.createRef();
    this.albumekle_748687_value_kuikaTextInputRef = React.createRef();
    this.albumekle_58775_value_kuikaTextInputRef = React.createRef();
    this.albumekle_561781_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      sb_SelectFormat: [],
      SelectParcalar: [],
      SelectTur: [],
      SelectAlbumByID: [],
      SetValueOf: "",
      PhotoFromGallery: "",
      NAVIGATE: "",
      DeleteParcalar2: 0,
      DeleteParcalar: 0,
      SaveAlbum2: 0,
      Notify: false,
      isComp455678Visible: "hidden",
      isComp50245Visible: "hidden",
      isComp788722Visible: "hidden",
      isCompalbumekle_47499AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("albumekle", "");
      return;
    }

    if (true) {
      await this.AlbumEklePageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompalbumekle_47499AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("albumekle", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("albumekle", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AlbumEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      albumekle_575233_value: this.state.SelectAlbumByID?.at?.(0)?.albumFoto ?? undefined,
      albumekle_161175_value: this.state.SelectAlbumByID?.at?.(0)?.isim ?? undefined,
      albumekle_574065_value: this.state.SelectAlbumByID?.at?.(0)?.tarz ?? undefined,
      albumekle_797852_value: this.state.SelectAlbumByID?.at?.(0)?.albumFormat ?? undefined,
      albumekle_682017_value: this.state.SelectAlbumByID?.at?.(0)?.sanatci ?? undefined,
      albumekle_637281_value: this.state.SelectAlbumByID?.at?.(0)?.dil ?? undefined,
      albumekle_748687_value: this.state.SelectAlbumByID?.at?.(0)?.cikisYili ?? undefined,
      albumekle_58775_value: this.state.SelectAlbumByID?.at?.(0)?.stokAdeti ?? undefined,
      albumekle_111419_value: this.state.SelectAlbumByID?.at?.(0)?.satisStatus ?? undefined,
      albumekle_828977_value: this.state.SelectAlbumByID?.at?.(0)?.dijitalStatus ?? undefined,
      albumekle_707138_value: this.state.SelectAlbumByID?.at?.(0)?.icerik ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  AlbumEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      albumid_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_id ?? this.props.screenInputs.prm_id,
        "Guid"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_id ?? this.props.screenInputs.prm_id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AlbumEkle/AlbumEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sb_SelectFormat = result?.data.sb_SelectFormat;
    stateVars.SelectParcalar = result?.data.selectParcalar;

    stateVars.SelectTur = result?.data.selectTur;

    formVars.albumekle_797852_value =
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].albumFormat
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].albumFormat
        : null;
    formVars.albumekle_797852_options = stateVars.SelectTur;
    stateVars.SelectAlbumByID = result?.data.selectAlbumByID;
    formVars.albumekle_575233_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].albumFoto
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].albumFoto
        : null
    );
    formVars.albumekle_161175_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].isim
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].isim
        : null
    );
    formVars.albumekle_574065_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].tarz
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].tarz
        : null
    );
    formVars.albumekle_797852_value =
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].albumFormat
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].albumFormat
        : null;
    formVars.albumekle_797852_options = stateVars.SelectTur;
    formVars.albumekle_682017_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].sanatci
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].sanatci
        : null
    );
    formVars.albumekle_637281_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].dil
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].dil
        : null
    );
    formVars.albumekle_748687_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].cikisYili
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].cikisYili
        : null
    );
    formVars.albumekle_58775_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].stokAdeti
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].stokAdeti
        : null
    );
    formVars.albumekle_111419_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].satisStatus
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].satisStatus
        : null
    );
    formVars.albumekle_828977_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].dijitalStatus
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].dijitalStatus
        : null
    );
    formVars.albumekle_707138_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].icerik
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].icerik
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AlbumEklePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AlbumEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.albumekle_575233_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].albumFoto
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].albumFoto
        : null
    );

    formVars.albumekle_161175_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].isim
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].isim
        : null
    );

    formVars.albumekle_574065_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].tarz
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].tarz
        : null
    );

    formVars.albumekle_797852_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].albumFormat
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].albumFormat
        : null
    );

    stateVars.dataSource_797852 = this.state.SelectTur;
    stateVars.dataSource_797852 = this.state.SelectTur;
    formVars.albumekle_682017_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].sanatci
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].sanatci
        : null
    );

    formVars.albumekle_637281_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].dil
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].dil
        : null
    );

    formVars.albumekle_748687_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].cikisYili
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].cikisYili
        : null
    );

    formVars.albumekle_58775_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].stokAdeti
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].stokAdeti
        : null
    );

    formVars.albumekle_111419_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].satisStatus
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].satisStatus
        : null
    );

    formVars.albumekle_828977_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].dijitalStatus
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].dijitalStatus
        : null
    );

    formVars.albumekle_707138_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAlbumByID?.length > 0
        ? stateVars.SelectAlbumByID[0].icerik
        : this.state.SelectAlbumByID?.length > 0
        ? this.state.SelectAlbumByID[0].icerik
        : null
    );

    stateVars.dataSource_561781 = this.state.SelectParcalar;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AlbumEkleComponent_659983_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AlbumEkleComponent_292885_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("albumekle_575233_value", "string"),
      ReactSystemFunctions.convertToTypeByName("null", "any"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AlbumEkleComponent_699275_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      ReactSystemFunctions.convertToTypeByName("", "string")
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("albumekle_575233_value", "string"),
      ReactSystemFunctions.value(this, stateVars.PhotoFromGallery),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AlbumEkleComponent_455678_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AlbumEkle",
      "TurEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "507965",
      null,
      "right",
      null,
      "50%",
      "100%",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AlbumEkleComponent_50245_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ParcaEkle",
      "prm_AlbumID",
      ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.prm_id ?? this.props.screenInputs.prm_id, "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "ParcaEkle",
      "prm_EserID",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AlbumEkle",
      "ParcaEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "120473",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AlbumEkleComponent_867012_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ParcaEkle",
      "prm_AlbumID",
      ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.prm_id ?? this.props.screenInputs.prm_id, "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "ParcaEkle",
      "prm_EserID",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.sb_SelectFormat?.length > 0
            ? stateVars.sb_SelectFormat[0].id
            : this.state.sb_SelectFormat?.length > 0
            ? this.state.sb_SelectFormat[0].id
            : null
        ),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AlbumEkle",
      "ParcaEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "83654",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AlbumEkleComponent_860459_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "albumekle_561781_value", "id"),
        "Guid"
      ),
      albumid_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_id ?? this.props.screenInputs.prm_id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AlbumEkle/AlbumEkleComponent_860459_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteParcalar2 = result?.data.deleteParcalar2;
    stateVars.SelectParcalar = result?.data.selectParcalar;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AlbumEkleComponent_860459_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AlbumEkleComponent_860459_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_561781 = this.state.SelectParcalar;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AlbumEkleComponent_47499_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_id ?? this.props.screenInputs.prm_id,
        "Guid"
      ),
      AlbumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_id ?? this.props.screenInputs.prm_id,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f2040364_4965_f667_cf51_c02ed939cd4c_confirmation",
        this.defaultML,
        "Silmek istiyor munsuz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "AlbumEkle/AlbumEkleComponent_47499_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteParcalar = result?.data.deleteParcalar;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AlbumEkleComponent_47499_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AlbumEkleComponent_47499_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AlbumEkleComponent_56852_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CikisYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "albumekle_748687_value", "value", "", "", "")
        ),
        "string"
      ),
      DijitalStatus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "albumekle_828977_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Dil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "albumekle_637281_value", "value", "", "", "")
        ),
        "string"
      ),
      Isim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "albumekle_161175_value", "value", "", "", "")
        ),
        "string"
      ),
      AlbumFormat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "albumekle_797852_value", "value", "SelectTur", "id", "id")
        ),
        "Guid"
      ),
      AlbumFoto_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "albumekle_575233_value", "value", "", "", "")
        ),
        "string"
      ),
      Sanatci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "albumekle_682017_value", "value", "", "", "")
        ),
        "string"
      ),
      SatisStatus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "albumekle_111419_value", "value", "", "", "")
        ),
        "boolean"
      ),
      StokAdeti_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "albumekle_58775_value", "value", "", "", "")
        ),
        "string"
      ),
      Tarz_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "albumekle_574065_value", "value", "", "", "")
        ),
        "string"
      ),
      Icerik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "albumekle_707138_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prm_id ?? this.props.screenInputs.prm_id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AlbumEkle/AlbumEkleComponent_56852_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveAlbum2 = result?.data.saveAlbum2;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AlbumEkleComponent_56852_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AlbumEkleComponent_56852_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "8e6ecccf_37f6_19fd_4e35_588b01b26f8b_notify",
        this.defaultML,
        "İşlem Tamamlandı"
      ),
      ReactSystemFunctions.convertToTypeByName("success", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "number"),
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [507965, 120473, 83654] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AlbumEklePageInit();
    }
  }
}
