import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDagitimListKUMULE_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDagitimListKUMULE_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  spDagitimListKUMULE: any[];
  spDagitimListKUMULE_dummy: any[];
  isComp23321Visible: "visible" | "hidden";
  isComp452978Visible: "visible" | "hidden";
}

export class DagitimListKUMULE_ScreenBase extends React.PureComponent<IDagitimListKUMULE_ScreenProps, any> {
  dagitimlistkumule_804151_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "ad414242-6c94-402a-9b83-b8dec04a7220",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 824233, PropertyName: "value", Value: "DAĞITIM (Grup bazlı toplam tutar)" },
        { Id: 804151, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 897396, PropertyName: "title", Value: "Yıl" },
        { Id: 361421, PropertyName: "value", Value: "[datafield:quarteryear]" },
        { Id: 141808, PropertyName: "title", Value: "Q" },
        { Id: 37105, PropertyName: "value", Value: "[datafield:quarterkod]" },
        { Id: 198114, PropertyName: "title", Value: "Dağıtım Yeri" },
        { Id: 349114, PropertyName: "value", Value: "[datafield:dagitimyeri]" },
        { Id: 919427, PropertyName: "title", Value: "Eser Sahibi Tutar" },
        { Id: 289078, PropertyName: "value", Value: "[datafield:thakedistutar]" },
        { Id: 819432, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 827214, PropertyName: "title", Value: "MUZ Pay" },
        { Id: 172158, PropertyName: "value", Value: "[datafield:tmuztutar]" },
        { Id: 532550, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 541194, PropertyName: "title", Value: "Eser Sahibi Pay" },
        { Id: 656580, PropertyName: "value", Value: "[datafield:tstopajdahiltutar]" },
        { Id: 149528, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 312147, PropertyName: "title", Value: "Stopaj Tutar" },
        { Id: 53330, PropertyName: "value", Value: "[datafield:tstopajtutar]" },
        { Id: 830809, PropertyName: "value", Value: "[datafield:_simge]" },
        { Id: 332831, PropertyName: "title", Value: "Ödenecek Tutar" },
        { Id: 86842, PropertyName: "value", Value: "[datafield:todenecektutar]" },
        { Id: 815627, PropertyName: "value", Value: "[datafield:_simge]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dagitimlistkumule_804151_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      getUserInfo: [],
      spDagitimListKUMULE: [],
      isComp23321Visible: "hidden",
      isComp452978Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dagitimlistkumule", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DagitimListKUMULEPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dagitimlistkumule", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dagitimlistkumule", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DagitimListKUMULEPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DagitimListKUMULEPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      QuarterYear_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.QYear ?? this.props.screenInputs.qyear,
        "string"
      ),
      QuarterKod_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.QKod ?? this.props.screenInputs.qkod,
        "string"
      ),
      DagitimYapilacakKodu_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DKodu ?? this.props.screenInputs.dkodu,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DagitimListKUMULE/DagitimListKUMULEPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.spDagitimListKUMULE = result?.data.spDagitimListKUMULE;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DagitimListKUMULEPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DagitimListKUMULEPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_804151 = this.state.spDagitimListKUMULE;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DagitimListKUMULEComponent_511843_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
